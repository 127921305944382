import React, { Component } from 'react'
import Select from 'react-select'
import classnames from 'classnames'

import yartsFareRules from '../data/yarts_fare_rules.json'
import yartsFareAttributes from '../data/yarts_fare_attributes.json'
import yartsZones from '../data/yarts_zones.json'

import styles from './FareCalculator.scss'

// make a drop down that selects from zones
// when you select a zone, populate possible destination zones dropdown
// when you hit submit, check all fare rules from origin to destination for cost
// display cost
const sortByLabel = (a, b) => {
    if(a.label < b.label) { return -1; }
    if(a.label > b.label) { return 1; }
    return 0;
}
    
                
class FareCalculator extends Component {
    constructor(props) {
        super(props)
        this.state = {
            originZoneId: null,
            destinationZoneId: null,
        }
    }

    handleOriginZoneChange(option) {
        this.setState({originZone: option})
        this.setState({destinationZone: null})
    }

    handleDestinationZoneChange(option) {
        this.setState({destinationZone: option})
    }

    render() {
        const {originZone,
            destinationZone } = this.state

        const zonesById = yartsZones.data.reduce((acc,zone) => {
            acc[zone.zone_id] = zone
            return acc
        }, {})

        const possibleOrigins = yartsFareRules.data.reduce((acc,rule) => {
            if(rule.origin_id){
                acc[rule.origin_id] = zonesById[rule.origin_id]
            }
            return acc
        }, {})

        const zoneOptions = Object.keys(possibleOrigins).map(zone_id => {
            // need to check that these zones actually have rules
            const zone = zonesById[zone_id]
            return {label: zone.name, value: zone.zone_id}
        })

        zoneOptions.sort(sortByLabel)

    
        const faresById = yartsFareAttributes.data.reduce((acc,fare) => {
            acc[fare.fare_id] = fare
            return acc
        }, {})

        let destinationZoneOptions = []
        if (originZone) {
            // destinationZoneOptions
            // get all destination zone ids that have rules from origin
            const possibleDestinationZoneIds = {} 
            yartsFareRules.data.forEach(rule => {
                if (rule.origin_id == originZone.value) {
                    possibleDestinationZoneIds[rule.destination_id] = rule.destination_id
                }
            })
            destinationZoneOptions = Object.keys(possibleDestinationZoneIds).map(zoneId => {
                return {value: zoneId, label: zonesById[zoneId].name}
            })
        }

        destinationZoneOptions.sort(sortByLabel)

        const destinationClasses = classnames({
            'fare-calculator-destination-wrapper': true,
            'disabled': !originZone,
        })

        let fare = null
        if(originZone && destinationZone){
            // find rule that matches
            yartsFareRules.data.forEach(rule => {
                if(originZone.value == rule.origin_id 
                    &&  destinationZone.value == rule.destination_id){
                        fare = faresById[rule.fare_id].price
                }
            })
        }

        const fareBoxClasses = classnames({
            'calculator-result-box': true,
            'no-fare': !fare,
        })

        return (
            <div className="fare-calculator-box">
            <label>
                Origin:
                <Select
                    options={zoneOptions}
                    onChange={this.handleOriginZoneChange.bind(this)}
                    value={originZone}
                    />
            </label>
            <span className={destinationClasses}>
            <label >
                Destination:
                <Select
                    options={destinationZoneOptions}
                    onChange={this.handleDestinationZoneChange.bind(this)}
                    value={destinationZone}
                    />
            </label>
            </span>
            <div className={fareBoxClasses}>
                <span className='calculator-result-label'>Regular One-Way Cash Fare:</span>
                <span className='calculator-result-fare'>${fare || <span>&mdash;</span>  }</span>
            </div>
            </div>
        )
    }
}

export default FareCalculator